import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Recaptcha from 'react-google-recaptcha';

import mdToHtml from '../util/mdToHtml';

import topBgImg from '../../static/img/ConcreteBG_light_top.jpg';
import bottomBgImg from '../../static/img/ConcreteBG_light_bottom.jpg';

const StyledContact = styled.div`
	padding: 4rem ${(p) => p.theme.gutter};
	background-color: ${(p) => p.theme.color.snowflake};
	background-image: url(${topBgImg}), url(${bottomBgImg});
	background-size: 100% auto, 100% auto;
	background-position: center top, center bottom;
	background-repeat: no-repeat;
	display: flex;

	.left {
		width: 50%;
		display: flex;
		align-items: flex-start;
		justify-content: center;
		flex-direction: column;
		padding-right: 1rem;
		h2 {
			font-size: 2.5rem;
			font-weight: 700;
			line-height: 1.125;
			color: ${(p) => p.theme.color.grey};
			max-width: 10em;
			margin-bottom: 0.7em;
		}
		.text {
			font-size: 1.5625rem;
			line-height: 1.4;
			font-weight: 200;
			color: ${(p) => p.theme.color.grey};
			margin: 0 0 1.5rem;
			max-width: 10em;
		}
		a {
			font-size: 2rem;
			line-height: 2.5rem;
			color: ${(p) => p.theme.color.waterBlue};
			font-family: ${(p) => p.theme.fonts.nav};
			position: relative;
			&::after {
				content: '';
				background-color: ${(p) => p.theme.color.waterBlue};
				transition: 0.5s;
				width: 100%;
				height: 3px;
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				opacity: 0;
			}
			&:hover::after {
				opacity: 1;
			}
		}
	}
	.map {
		display: flex;
		&.mobile-map {
			display: none;
		}
		margin-top: 1.5rem;
		.img-container {
			display: flex;
			flex-direction: column;
			justify-content: center;
			width: 40%;
			margin-left: -1rem;
		}
		.text-container {
			display: flex;
			flex-direction: column;
			justify-content: center;
			width: 50%;
			margin-right: 5%;
			p {
				font-size: 1rem;
				max-width: 9em;
				color: ${(p) => p.theme.color.grey};
				font-style: italic;
			}
		}
	}
	.right {
		width: 50%;
		display: flex;
		justify-content: center;
		flex-direction: column;
		input {
			padding: 0.5rem 1rem;
			width: 80%;
		}
		label {
			position: absolute !important;
			clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
			clip: rect(1px, 1px, 1px, 1px);
			padding: 0 !important;
			border: 0 !important;
			height: 1px !important;
			width: 1px !important;
			overflow: hidden;
		}
		textarea {
			padding: 0.5rem 1rem;
			width: 80%;
			height: 8rem;
			resize: none;
		}
		.blueButton {
			padding: 0.4rem 1.7rem;
			margin-top: 0.5rem;
		}
		.thanks {
			display: flex;
			justify-content: center;
			flex-direction: column;
			width: 220px;
			border-top: 2px solid #707070;
			border-bottom: 2px solid #707070;
			h2 {
				color: #707070;
				width: 170px;
			}
			p {
				color: #707070;
				margin-top: 1rem;
				font-weight: 200;
				width: 200px;
			}
		}
	}

	@media all and (min-width: 1200px) {
		padding: 4rem ${(p) => p.theme.gutterWide};
	}
	@media all and (min-width: 1600px) {
		padding: 6rem ${(p) => p.theme.gutterXWide};
		.left {
			h3 {
				font-size: 2rem;
				line-height: 2.5rem;
			}
			.text {
				font-size: 1.5rem;
				line-height: 2rem;
				width: 260px;
			}
			a {
				font-size: 2.5rem;
				line-height: 3rem;
			}
		}
		.map {
			.img-container {
				width: 50%;
			}
			.text-container {
				width: auto;
				margin-right: 0;
				p {
					font-size: 1.3rem;
					line-height: 1.7rem;
					max-width: 220px;
				}
			}
		}
		.right {
			.field:not(:last-child) {
				margin-bottom: 1rem;
			}
			input {
				font-size: 1.3rem;
			}
			textarea {
				font-size: 1.3rem;
				height: 10rem;
			}
			.thanks {
				width: 300px;
				h2 {
					width: 230px;
				}
				p {
					width: 280px;
					margin-top: 2rem;
				}
			}
		}
	}
	@media all and (max-width: 850px) {
		.right {
			input,
			textarea {
				width: 100%;
			}
		}
	}
	@media all and (max-width: 768px) {
		flex-direction: column;
		.left {
			width: 100%;
			padding-right: 0;
		}
		.map {
			padding-bottom: 2rem;
			.img-container {
				width: 25%;
			}
			.text-container p {
				max-width: none;
			}
		}

		.right {
			width: 100%;
		}
	}
	@media all and (max-width: 668px) {
		.map {
			.img-container {
				width: 30%;
			}
			.text-container {
				margin-right: 0;
				width: 60%;
			}
		}
	}
	@media all and (max-width: 500px) {
		padding: 3rem ${(p) => p.theme.gutterMobile};

		.mobile-map {
			display: flex;
		}
		.desktop-map {
			display: none !important;
		}
		form {
			margin: 2rem 0 0;
		}

		.map {
			padding-bottom: 0;
			.img-container {
				width: 35%;
			}
			.text-container {
				margin-left: 1.5rem;
				margin-bottom: 1rem;
				width: calc(65% - 1.5rem);
			}
		}
	}
	@media all and (max-width: 450px) {
		.map {
			flex-direction: column-reverse;
			.img-container {
				width: 230px;
				margin: 0 auto;
			}
			.text-container {
				width: 100%;
				margin-left: 0;
			}
		}
	}
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		.map img {
			height: 100%;
		}
	}
`;

const Contact = ({ title, text, phone, map }) => {
	const [thanks, setThanks] = useState(false);
	const [height, setHeight] = useState(315);
	const [capErr, setCapErr] = useState(false);
	const [captchaVal, setCaptchaVal] = useState('');

	const recaptchaSitekey = '6Le-RWggAAAAAB5jtIXWk-mXvTPLvddi8p1DWe1F';

	useEffect(() => {
		setThanks(window.location.search === '?thanks=true');
		const handleResize = () => {
			if (window.innerWidth > 1600) setHeight(390);
		};
		handleResize();
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	});

	const handleSubmit = (e) => {
		if (!captchaVal) {
			setCapErr(true);
			e.preventDefault();
		}
	};

	return (
		<StyledContact id="form">
			<div className="left">
				<h2>{title}</h2>
				<p className="text">{text}</p>
				<a href={phone.raw}>{phone.formatted}</a>
				<div className="map desktop-map">
					<div className="img-container">
						<img src={map.img} alt="map" />
					</div>
					<div
						className="text-container"
						dangerouslySetInnerHTML={{ __html: mdToHtml(map.text) }}
					/>
				</div>
			</div>
			<div className="right">
				{thanks && (
					<>
						<div
							className="thanks"
							id="mobileThanksHeight"
							style={{ height: `${height}px` }}
						>
							<h2>Thanks for contacting us!</h2>
							<p>EK Construction Co. will respond to you shortly.</p>
						</div>
						<div className="map mobile-map">
							<div className="img-container">
								<img src={map.img} alt="map" />
							</div>
							<div
								className="text-container"
								dangerouslySetInnerHTML={{ __html: mdToHtml(map.text) }}
							/>
						</div>
					</>
				)}

				{!thanks && (
					<>
						<form
							method="post"
							name="Contact Form"
							data-netlify="true"
							data-netlify-recaptcha="true" // new Netlify data attribute
							data-netlify-honeypot="bot-field"
							action="/contact?thanks=true#form"
							onSubmit={handleSubmit}
						>
							<input type="hidden" name="form-name" value="Contact Form" />
							<input type="hidden" name="bot-field" />
							<div className="field">
								<label htmlFor="name">Name:</label>
								<input
									type="text"
									id="name"
									name="name"
									placeholder="Name"
									required
								/>
							</div>
							<div className="field">
								<label htmlFor="email">Email:</label>
								<input
									type="email"
									id="email"
									name="email"
									placeholder="Email"
									required
								/>
							</div>
							<div className="field">
								<label htmlFor="phone">Phone:</label>
								<input
									type="text"
									id="phone"
									name="phone"
									placeholder="Phone"
								/>
							</div>
							<div className="field">
								<label htmlFor="message">Message:</label>
								<textarea
									type="text"
									id="message"
									name="message"
									placeholder="Message"
									required
								/>
							</div>
							{/* <div
								data-netlify-recaptcha="true"
								onChange={(val) => {
									setCaptchaVal(val);
								}}
							></div> */}
							<Recaptcha
								sitekey={recaptchaSitekey}
								onChange={(val) => {
									setCaptchaVal(val);
								}}
							/>
							<p
								style={{
									display: capErr ? 'block' : 'none',
									color: 'red',
									marginTop: '1rem',
								}}
							>
								Please confirm you are not a robot.
							</p>
							<button className="blueButton" type="submit">
								SUBMIT
							</button>
						</form>
						<div className="map mobile-map">
							<div className="img-container">
								<img src={map.img} alt="map" />
							</div>
							<div className="text-container">
								<p>{map.text}</p>
							</div>
						</div>
					</>
				)}
			</div>
		</StyledContact>
	);
};

export default Contact;
