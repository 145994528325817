import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ContactPageTemplate from '../templates/contact-template';

const copy = {
	description:
		"Let's discuss your next project. EK Construction Co. services the Muskoka, Simcoe, York and GTA Regions.",
	masthead: { title: 'Contact Us', img: '/img/EK_Contact_Masthead.jpg' },
	form: {
		title: "Let's discuss your next project.",
		text: 'Complete this form or call us anytime at',
		phone: {
			raw: 'tel:+17059944755',
			formatted: '(705) 994-4755',
		},
		map: {
			img: '/img/map.png',
			text:
				'**EK Construction Co.** services the Muskoka, Simcoe, York and GTA Regions.',
		},
	},
	footer: {
		text: 'Learn more about us and the work we do.',
		img: '/img/EK_Contact_Bottom.jpg',
		cta: {
			text: 'ABOUT US',
			link: '/about/',
		},
	},
};

const ContactPage = () => (
	<Layout>
		<SEO title="Contact" description={copy.description} />
		<ContactPageTemplate copy={copy} />
	</Layout>
);

export default ContactPage;
