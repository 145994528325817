import React from 'react';

import Masthead from '../components/masthead';
import Footer from '../components/footer-cta';
import Contact from '../components/contact-form';

const ContactTemplate = ({ copy }) => {
	const { masthead, form, footer } = copy;
	return (
		<>
			<Masthead title={masthead.title} img={masthead.img} />
			<Contact
				title={form.title}
				text={form.text}
				phone={form.phone}
				map={form.map}
			/>
			<Footer
				text={footer.text}
				cta={footer.cta}
				img={footer.img}
				page="contact"
			/>
		</>
	);
};

export default ContactTemplate;
